import React, { useState, useEffect } from "react";
import { getUrlParams } from "./../../../utils/utils";
import Layout from "./../../../components/Layout";
import Statistiques from "./../../../nav/Admin/Commerces/Statistiques";
import { Helmet } from "react-helmet";
import firebase from "gatsby-plugin-firebase";
import { getUser } from "./../../../auth";
import Error403 from "../../../nav/Error403";
import { TailSpin } from "react-loader-spinner";
import {
  getUserFormatted,
  getOfferFormatted,
  getShopFormatted,
} from "./../../../utils/formating";

const HandleUserStates = ({ user, users, shop, offers }) => {
  if (user === undefined) {
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else if (user && user.subscription.type === "admin") {
    return <Statistiques users={users} shop={shop} offers={offers} />;
  } else {
    return <Error403 />;
  }
};

const StatistiquesPage = () => {
  const [user, setUser] = useState(undefined);
  const [shop, setShop] = useState(null);
  const [users, setUsers] = useState([]);
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    getUser(setUser);
    const id = getUrlParams("id");
    firebase
      .firestore()
      .collection("shops")
      .doc(id)
      .get()
      .then((doc) => {
        setShop(getShopFormatted(doc));
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
    firebase
      .firestore()
      .collection("users")
      .get()
      .then((docs) => {
        let idx = 0;
        let newUsers = [];

        while (idx < docs.docs.length) {
          newUsers.push(getUserFormatted(docs.docs[idx]));
          idx++;
        }
        setUsers(newUsers);
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
    firebase
      .firestore()
      .collection("offers")
      .get()
      .then((docs) => {
        let idx = 0;
        let newOffers = [];

        while (idx < docs.docs.length) {
          newOffers.push(getOfferFormatted(docs.docs[idx]));
          idx++;
        }
        setOffers(newOffers);
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Statistiques du commerce</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Tu pourras ici voir les statistiques de ce commerce."
        />
        <meta
          name="keywords"
          content="réductions,jeunes,admin,commerce,statistiques"
        />
        <link
          rel="canonical"
          href="https://wynit.com/admin/commerces/statistiques"
        />
      </Helmet>
      <HandleUserStates user={user} users={users} shop={shop} offers={offers} />
    </Layout>
  );
};

export default StatistiquesPage;
