import React from "react"
import {
  Wrapper,
} from "./OffersStatistics.module.css"
import OfferCard from "./../../../../../components/OfferCard"

const EXCLUDE_USERS = [
  "gualtieri.hugo@hotmail.com",
  "thomas.freemium@gmail.com",
  "thomas.mensuel@gmail.com",
  "thomas.annuel@gmail.com",
  "ardito.thomas13@gmail.com",
  "thomas.wynit@gmail.com",
  "ardito.thomas11@gmail.com",
  "m_ardito@orange.fr",
  "aniceeperez@yahoo.com",
  "lainem615@gmail.com"
]

const DisplayMostUsedOffer = ({offer}) => {
    return (
      <>
        <OfferCard
          image={offer["image"]}
          date={""}
          category={offer["category"]}
          shopName={offer["shopName"]}
          title={offer["title"]}
        />
        <p>Utilisée {offer["numberOfUse"]} fois</p>
      </>
    )
}

const OffersStatistics = ({users, shop, offers}) => {
  function getOffersUsedLast7DaysOfTheShop() {
    var i = 0
    var j = 0
    var now = Date.now() * 1000
    var result = 0

    while (i < users.length) {
      while (j < users[i]["usedOffers"].length) {
        if (!EXCLUDE_USERS.includes(users[i]["email"]) && users[i]["usedOffers"][j]["shopId"] === shop["id"] && users[i]["usedOffers"][j]["date"] > (now - 604800000000))
          result++
        j++
      }
      i++
      j = 0
    }
    return (result)
  }

  function getOffersUsedLast30DaysOfTheShop() {
    var i = 0
    var j = 0
    var now = Date.now() * 1000
    var result = 0

    while (i < users.length) {
      while (j < users[i]["usedOffers"].length) {
        if (!EXCLUDE_USERS.includes(users[i]["email"]) && users[i]["usedOffers"][j]["shopId"] === shop["id"] && users[i]["usedOffers"][j]["date"] > (now - (604800000000 * 4)))
          result++
        j++
      }
      i++
      j = 0
    }
    return (result)
  }

  function getOffersUsedOfTheShop() {
    var i = 0
    var j = 0
    var result = 0

    while (i < users.length) {
      while (j < users[i]["usedOffers"].length) {
        if (!EXCLUDE_USERS.includes(users[i]["email"]) && users[i]["usedOffers"][j]["shopId"] === shop["id"])
          result++
        j++
      }
      i++
      j = 0
    }
    return (result)
  }

  const pickHighest = (obj, num = 1) => {
    const requiredObj = {};
    if(num > Object.keys(obj).length){
       return false;
    };
    Object.keys(obj).sort((a, b) => obj[b] - obj[a]).forEach((key, ind) =>
    {
       if(ind < num){
          requiredObj[key] = obj[key];
       }
    });
    return Object.keys(requiredObj);
  };

  const RenderMostUsedOffer = () => {
    var offersUtilisation = {};
    var i = 0
    var j = 0

    while (i < users.length) {
      while (j < users[i]["usedOffers"].length) {
        if (users[i]["usedOffers"][j]["shopId"] === shop["id"] && offersUtilisation[users[i]["usedOffers"][j]["id"]] >= 1)
          offersUtilisation[users[i]["usedOffers"][j]["id"]]++
        else if (users[i]["usedOffers"][j]["shopId"] === shop["id"])
          offersUtilisation[users[i]["usedOffers"][j]["id"]] = 1
        j++
      }
      i++
      j = 0
    }

    i = 0
    var mostUsedOffer = pickHighest(offersUtilisation, 1);
    var result = {}

    if (mostUsedOffer === false)
      return <p>N/A</p>

    while (i < offers.length) {
      if (mostUsedOffer.includes(offers[i]["id"]))
        result = offers[i]
      i++
    }

    result["numberOfUse"] = offersUtilisation[result["id"]]
    return <DisplayMostUsedOffer offer={result}/>
  }

  return (
    <div className={Wrapper}>
      <p>Nombre d'offres utilisées sur les 7 derniers jours: {getOffersUsedLast7DaysOfTheShop()}</p>
      <p>Nombre d'offres utilisées sur les 30 derniers jours: {getOffersUsedLast30DaysOfTheShop()}</p>
      <p>Nombre d'offres utilisées au total: {getOffersUsedOfTheShop()}</p>
      <p>Offre la + utilisée: </p>
      <RenderMostUsedOffer />
    </div>
  )
}

export default OffersStatistics
