import React, { useEffect, useState } from "react"
import styled from "styled-components"
import CarteCommentaire from "../../../../../components/CarteCommentaire"

const EXCLUDE_USERS_BY_ID = [
    "Tugn4cZtf3WUqCAWJ2YKT8o9tUd2",
    "NITpLXUT74PmOeXkreCt7yxcJAg1",
    "sAerXwAJ1iblXe8uysB11fVcsB22",
    "nKxAeGPytih3o9bAjRhjk58fhv53",
    "wK5JsN2EEtZMn5rNxBJcDjh4W0z1",
    "whEsbYJg0vU0kr2C9eHufUBKfjt1",
    "rVWeKzwCi4M4LNb9ts6kMNoDmwC2",
    "8MXIyROc9ycsoGePmjHXjpgcprr2",
    "649VPKnaa4ei9Dtwmb2srH5pFWm2",
    "Ic4NTQDs6hZCqrcEpUr7TdSIwLH3"
]  

const Wrapper = styled.section`
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`

const Commentaires = ({ shop }) => {
    const [rates, setRates] = useState([])

    useEffect(() => {
        var idx = 0
        var ratesTmp = []

        while (idx < shop["rates"].length) {
            if (!EXCLUDE_USERS_BY_ID.includes(shop["rates"][idx]["userId"]) && shop["rates"][idx]["comment"] !== "") {
                ratesTmp.push(shop["rates"][idx]);
            }
            idx++
        }
        setRates(ratesTmp)
    }, [])

  return (
    <Wrapper>
        {rates.length > 0 ? rates.map((rate) => (
            <CarteCommentaire rate={rate} />
        )) : <p>Ce commerce n'a pas encore de commentaire.</p>}
    </Wrapper>
  )
}

export default Commentaires