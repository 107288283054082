import React from "react"
import {
  Wrapper,
  DescriptionWrapper,
  SectionWrapper,
} from "./Statistiques.module.css"
import "./../../../../css/Utils.css"
import { OrangeTitle } from "./../../../../css/Titre.module.css"
import Title from "./../../../../components/Title"

import OffersStatistics from "./OffersStatistics"
import UsersStatistics from "./UsersStatistics"
import MoyenneNotes from "./MoyenneNotes"
import Commentaires from "./Commentaires"

const Statistiques = ({ users, shop, offers }) => {

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>{shop["name"]}</h1>
      <p className={DescriptionWrapper}>
        {shop["description"]}
      </p>
      <div className={SectionWrapper}>
        <Title title="Offres utilisées" />
        <OffersStatistics users={users} shop={shop} offers={offers}/>
      </div>
      <div className={SectionWrapper}>
        <Title title="Les clients qui viennent chez vous" />
        <UsersStatistics users={users} shop={shop}/>
      </div>
      <div className={SectionWrapper}>
        <Title title="Moyenne des notes" />
        <MoyenneNotes shop={shop}/>
      </div>
      <div className={SectionWrapper}>
        <Title title="Commentaires" />
        <Commentaires shop={shop}/>
      </div>
    </div>
  )
}

export default Statistiques
