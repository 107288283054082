export const getUrlParams = name => {
  const urlParams = new URLSearchParams(window.location.search)

  if (!urlParams) return null

  const param = urlParams.get(name)
  return param
}

export const stringFormatedForUrl = str => {
  let result = ""
  let idx = 0

  while (idx < str.length) {
    if (str[idx] === " ") {
      result += "%20"
    } else if (str[idx] === "\n") {
      result += "%0A"
    } else {
      result += str[idx]
    }
    idx++
  }
  return result
}
