import React, { useState } from "react"
import styled from "styled-components"

import firebase from "gatsby-plugin-firebase"

import StarFilled from "./../../res/icons/rates/star_filled.png"
import StarNotFilled from "./../../res/icons/rates/star_not_filled.png"
import ColoredQuote from "./../../res/icons/commentaires/colored_quote.png"
import GreyQuote from "./../../res/icons/commentaires/grey_quote.png"

const Wrapper = styled.section`
    width: 350px;
    height: 200px;
    box-shadow: 2px 3px 10px 2px lightgrey;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
`

const CommentaryWrapper = styled.section`
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;

    p {
        margin-top: 10px;
        margin-left: 10px;
        text-align: justify;
    }
`

const UserAndRateWrapper = styled.section`
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
`

const UserAndRateContentWrapper = styled.section`
    p {
        font-weight: bold;
    }
`

const StarsWrapper = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 5px;
`

const CarteCommentaire = ({ rate }) => {
    const [username, setUsername] = useState("")

    firebase
      .firestore()
      .collection("users")
      .doc(rate["userId"])
      .get()
      .then(result => {
        setUsername(
          result._delegate._document.data.value.mapValue.fields.firstName
            .stringValue +
            " " +
            result._delegate._document.data.value.mapValue.fields.lastName
              .stringValue
        )
      })
      .catch(error => {
        //console.log("error: ", error)
      })

    const RenderStarsForAverageRate = ({rate}) => {
        if (rate === "5") {
            return(
                <StarsWrapper>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                </StarsWrapper>);
        } else if (rate === "4") {
            return(
                <StarsWrapper>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                    <img width={20} height={20} alt="star not filled" src={StarNotFilled}/>
                </StarsWrapper>);
        } else if (rate === "3") {
            return(
                <StarsWrapper>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                    <img width={20} height={20} alt="star not filled" src={StarNotFilled}/>
                    <img width={20} height={20} alt="star not filled" src={StarNotFilled}/>
                </StarsWrapper>);
        } else if (rate === "2") {
            return(
                <StarsWrapper>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                    <img width={20} height={20} alt="star not filled" src={StarNotFilled}/>
                    <img width={20} height={20} alt="star not filled" src={StarNotFilled}/>
                    <img width={20} height={20} alt="star not filled" src={StarNotFilled}/>
                </StarsWrapper>);
        } else if (rate === "1") {
            return(
                <StarsWrapper>
                    <img width={20} height={20} alt="star filled" src={StarFilled}/>
                    <img width={20} height={20} alt="star notfilled" src={StarNotFilled}/>
                    <img width={20} height={20} alt="star notfilled" src={StarNotFilled}/>
                    <img width={20} height={20} alt="star notfilled" src={StarNotFilled}/>
                    <img width={20} height={20} alt="star notfilled" src={StarNotFilled}/>
                </StarsWrapper>);
        } else {
            return(
                <StarsWrapper>
                    <img width={20} height={20} alt="star not filled" src={StarNotFilled}/>
                    <img width={20} height={20} alt="star not filled" src={StarNotFilled}/>
                    <img width={20} height={20} alt="star not filled" src={StarNotFilled}/>
                    <img width={20} height={20} alt="star not filled" src={StarNotFilled}/>
                    <img width={20} height={20} alt="star not filled" src={StarNotFilled}/>
                </StarsWrapper>);
        }
    }

  return (
    <Wrapper>
        <CommentaryWrapper>
            <img width={30} height={30} alt="colored quote" src={ColoredQuote}/>
            <p>{rate["comment"]}</p>
        </CommentaryWrapper>
        <div style={{flex: 1}}></div>
        <UserAndRateWrapper>
            <UserAndRateContentWrapper>
                <p>{username}</p>
                <RenderStarsForAverageRate rate={rate["rate"][0]} />
            </UserAndRateContentWrapper>
            <div style={{flex: 1}}></div>
            <img width={50} height={50} alt="grey quote" src={GreyQuote}/>
        </UserAndRateWrapper>
    </Wrapper>
  )
}

export default CarteCommentaire
