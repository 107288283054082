import React from "react"
import styled from "styled-components"
import StarFilled from "./../../../../../res/icons/rates/star_filled.png"
import StarHalfFilled from "./../../../../../res/icons/rates/star_half_filled.png"
import StarNotFilled from "./../../../../../res/icons/rates/star_not_filled.png"

const AverageWrapper = styled.section`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
`

const ContentWrapper = styled.section`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const EXCLUDE_USERS_BY_ID = [
    "Tugn4cZtf3WUqCAWJ2YKT8o9tUd2",
    "NITpLXUT74PmOeXkreCt7yxcJAg1",
    "sAerXwAJ1iblXe8uysB11fVcsB22",
    "nKxAeGPytih3o9bAjRhjk58fhv53",
    "wK5JsN2EEtZMn5rNxBJcDjh4W0z1",
    "whEsbYJg0vU0kr2C9eHufUBKfjt1",
    "rVWeKzwCi4M4LNb9ts6kMNoDmwC2",
    "8MXIyROc9ycsoGePmjHXjpgcprr2",
    "649VPKnaa4ei9Dtwmb2srH5pFWm2",
    "Ic4NTQDs6hZCqrcEpUr7TdSIwLH3"
]  

const MoyenneNotes = ({ shop }) => {

  const renderStarsForAverageRate = () => {
    const average = calculateAverageRates()

    if (average === "N/A") {
        return (null);
    } else if (average === 5) {
        return(
            <AverageWrapper>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
            </AverageWrapper>);
    } else if (average >= 4.5) {
        return(
            <AverageWrapper>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarHalfFilled}/>
            </AverageWrapper>);
    } else if (average >= 4.0) {
        return(
            <AverageWrapper>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
            </AverageWrapper>);
    } else if (average >= 3.5) {
        return(
            <AverageWrapper>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarHalfFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
            </AverageWrapper>);
    } else if (average >= 3.0) {
        return(
            <AverageWrapper>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
            </AverageWrapper>);
    } else if (average >= 2.5) {
        return(
            <AverageWrapper>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarHalfFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
            </AverageWrapper>);
    } else if (average >= 2.0) {
        return(
            <AverageWrapper>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
            </AverageWrapper>);
    } else if (average >= 1.5) {
        return(
            <AverageWrapper>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarHalfFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
            </AverageWrapper>);
    } else if (average >= 1.0) {
        return(
            <AverageWrapper>
                <img width={30} height={30} src={StarFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
            </AverageWrapper>);
    } else if (average >= 0.5) {
        return(
            <AverageWrapper>
                <img width={30} height={30} src={StarHalfFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
            </AverageWrapper>);
    } else {
        return(
            <AverageWrapper>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
                <img width={30} height={30} src={StarNotFilled}/>
            </AverageWrapper>);
    }
  }

  const calculateAverageRates = () => {
    if (
      shop["rates"] === undefined ||
      !shop["rates"] ||
      shop["rates"].length === 0
    ) {
      return "N/A"
    }
    var idx = 0
    var user = 0
    var average = 0
    while (idx < shop["rates"].length) {
        if (!EXCLUDE_USERS_BY_ID.includes(shop["rates"][idx]["userId"])) {
            average += Number(shop["rates"][idx]["rate"][0])
            user++
        }
        idx++
    }
    return Math.round((average / user) * 10) / 10
  }

  const totalRates = () => {
    if (
      shop["rates"] === undefined ||
      !shop["rates"] ||
      shop["rates"].length === 0
    ) {
      return "0 évaluation"
    }
    var idx = 0
    var user = 0
    while (idx < shop["rates"].length) {
        if (!EXCLUDE_USERS_BY_ID.includes(shop["rates"][idx]["userId"])) {
            user++
        }
        idx++
    }
    return (user + " évaluation" + (user > 1 ? "s" : ""));
  }

  return (
    <div>
        <AverageWrapper>
            {renderStarsForAverageRate()}
        </AverageWrapper>
        <ContentWrapper>
            <p>{calculateAverageRates() !== "N/A" ? (calculateAverageRates() + "/5") : calculateAverageRates()}</p>
            <div style={{paddingRight: "40px"}}></div>
            <p style={{color: "#CCCCCC"}}>{totalRates()}</p>
        </ContentWrapper>
    </div>
  )
}

export default MoyenneNotes
